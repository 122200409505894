import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule }       from '@angular/platform-browser';

import { AppRoutingModule }        from './app-routing.module';
import { AppComponent }            from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule }     from '@angular/service-worker';
import { SharedModule }            from './shared/shared.module';

@NgModule({
              declarations : [
                  AppComponent
              ],
              imports      : [
                  BrowserModule,
                  BrowserAnimationsModule,
                  AppRoutingModule,
                  SharedModule.forRoot(),
                  ServiceWorkerModule.register('ngsw-worker.js', {
                      enabled : !isDevMode(),
                      // Register the ServiceWorker as soon as the application is stable
                      // or after 30 seconds (whichever comes first).
                      registrationStrategy : 'registerWhenStable:5000'
                  })
              ],
              providers    : [],
              bootstrap    : [ AppComponent ]
          })
export class AppModule {
}
