import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {CdkTableModule} from "@angular/cdk/table";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatSidenavModule} from "@angular/material/sidenav";
import {
    ErrorStateMatcher, MatNativeDateModule,
    MatRippleModule,
    ShowOnDirtyErrorStateMatcher
} from "@angular/material/core";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatListModule} from "@angular/material/list";
import {CdkScrollableModule} from "@angular/cdk/scrolling";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {DataSharingService} from "../_services/data-sharing.service";
import {LoggerService} from "../_services/logger.service";
import {Meta, Title} from "@angular/platform-browser";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatChipsModule} from "@angular/material/chips";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTreeModule} from "@angular/material/tree";
import {MatTabsModule} from "@angular/material/tabs";
import {MatMenuModule} from "@angular/material/menu";
import {FooterComponent} from './footer/footer.component';
import {CdkAccordionModule} from "@angular/cdk/accordion";
import {MatExpansionModule} from "@angular/material/expansion";
import {RouterModule} from "@angular/router";
import {MatStepper, MatStepperModule} from "@angular/material/stepper";


@NgModule({
    declarations: [
        FooterComponent
    ],
    imports: [
        CommonModule,
        MatStepperModule,
        ReactiveFormsModule,
        RouterModule,
        MatFormFieldModule,
        MatChipsModule,
        MatInputModule,
        MatIconModule,
        MatProgressBarModule,
        CdkTableModule,
        CdkScrollableModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatGridListModule,
        MatIconModule,
        MatDatepickerModule,
        MatMenuModule,
        MatTabsModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatListModule,
        MatProgressBarModule,
        MatDialogModule,
        MatPaginatorModule,
        MatTreeModule,
        MatRippleModule,
        MatRadioModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatSelectModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatToolbarModule,
        ReactiveFormsModule
    ], exports: [
        CdkTableModule,
        CdkScrollableModule,
        CdkAccordionModule,
        FormsModule,
        MatButtonModule,
        MatChipsModule,
        MatCardModule,
        MatFormFieldModule,
        MatGridListModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        MatDialogModule,
        MatRippleModule,
        MatRadioModule,
        MatSidenavModule,
        MatTreeModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatSnackBarModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        ReactiveFormsModule,
        MatStepperModule,
        FooterComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                DataSharingService,
                LoggerService,
                Meta,
                Title,
            ]
        };
    }
}
