<div class="app-main-container">
    <mat-toolbar color="primary">
        <span class="font-logo" routerLink="/" style="cursor: pointer">CapChaos</span>
        <span class="toolbar-spacer"></span>
        <button name="Go to legal notice section" mat-icon-button routerLink="/legal/notice"><mat-icon>mail</mat-icon></button>
    </mat-toolbar>

    <mat-sidenav-container>
        <mat-sidenav-content>
            <div #mainContent>
                <router-outlet (activate)="onActivate($event)"></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
