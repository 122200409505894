import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, map, Observable, Subject, takeUntil} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Meta, Title} from "@angular/platform-browser";
import {MatDrawerMode} from "@angular/material/sidenav";
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable({
    providedIn: 'root'
})
export class DataSharingService implements OnDestroy {

    private destroyed = new Subject<void>();
    private displayNameMap = new Map([
        [Breakpoints.XSmall, 'Small'],
        [Breakpoints.Small, 'Small'],
        [Breakpoints.Medium, 'Small'],
        [Breakpoints.Large, 'Large'],
        [Breakpoints.XLarge, 'Large'],
    ]);
    private side = 'side';
    private over = 'over';
    public opened = new BehaviorSubject<boolean>(true);
    public mode = new BehaviorSubject<MatDrawerMode>(<"over" | "push" | "side">this.side);
    public currentScreenSize = new BehaviorSubject<string>('');
    public deviceIsMobile = new BehaviorSubject<boolean>(false);

    constructor(public breakpointObserver: BreakpointObserver,
                public isHandset$: BreakpointObserver,
                public isHandset2$: BreakpointObserver,
                public deviceService: DeviceDetectorService,
                private metaService: Meta,
                private titleService: Title
    ) {
        if (this.deviceService.isMobile()) {
            // this.opened.next(false);
            // this.mode.next(<"over" | "push" | "side">this.over);
        }
        breakpointObserver
            .observe([
                Breakpoints.XSmall,
                Breakpoints.Small,
                Breakpoints.Medium,
                Breakpoints.Large,
                Breakpoints.XLarge
            ])
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                    for (const query of Object.keys(result.breakpoints)) {
                        if (result.breakpoints[query]) {
                            this.currentScreenSize.next(this.displayNameMap.get(query) ?? 'Unknown');
                            // console.log(this.currentScreenSize.value);
                        }
                    }
                }
            );

        isHandset$.observe(Breakpoints.Handset)
            .pipe(
                takeUntil(this.destroyed),
                map((result) => {
                    this.deviceIsMobile.next(result.matches);
                    if (result.matches) {
                        this.opened.next(false);
                        this.mode.next(<"over" | "push" | "side">this.over);
                    } else {
                        this.opened.next(true);
                        this.mode.next(<"over" | "push" | "side">this.side);
                    }
                })
            ).subscribe();
        
    }

    setBowserTags(title: string, description: string, imageURL: string): void {
        this.titleService.setTitle(title);
        this.metaService.updateTag({property: 'og:title', content: title});
        this.metaService.updateTag({name: 'description', content: description});
        this.metaService.updateTag({name: 'og:description', content: description});
        this.metaService.updateTag({property: 'og:image', content: imageURL});
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
        this.destroyed.complete();
    }
}
