import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path         : '',
    loadChildren : () => import('./home/home.module').then(m => m.HomeModule),
    data         : {preload : false}
  },
  {
    path         : 'legal',
    loadChildren : () => import('./legal/legal.module').then(m => m.LegalModule),
    data         : {preload : false}
  },
  {
    path       : '**',
    redirectTo : '/',
    pathMatch  : 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
