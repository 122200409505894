import {ApplicationRef, Component, ElementRef, ViewChild} from '@angular/core';
import {LoggerService} from "./_services/logger.service";
import {SwUpdate} from "@angular/service-worker";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DataSharingService} from "./_services/data-sharing.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    @ViewChild('mainContent') private mainContentDiv!: ElementRef<HTMLElement>;

    constructor(
        private appRef: ApplicationRef,
        public dataService: DataSharingService,
        private log: LoggerService,
        private updates: SwUpdate,
        public snackBar: MatSnackBar
    ) {
        let updateReady = updates.checkForUpdate();
        updateReady
            .then((res) => {
                    if (res) {
                        console.log('updates Coming')
                        let updateIt = updates.activateUpdate();
                        updateIt.then((res) => {
                            console.log('updates loaded');

                            this.snackBar.open('Site updates available, reload in progress!', '', {
                                duration: 3000,
                                panelClass: ['green-snackbar']
                            });

                            setTimeout(() => {
                                document.location.reload();
                            }, 3000);

                        }, (rej) => {
                            console.log('updates loading failed');
                        });
                        // updateChecker.unsubscribe();
                    }
                }
            )
    }


    onActivate(_event: any): void {
        if (this.mainContentDiv) {
            (this.mainContentDiv.nativeElement as HTMLElement).scrollIntoView();
        }
    }

    public linkedIn(): void {
        window.open('https://www.linkedin.com/in/franzgerhart/', '_blank');
    }

    public closeMobileSidebar(): void {
        !!this.dataService.deviceService.isMobile() && this.dataService.opened.next(false);
    }

}

